import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import { events as eventService } from '@boss/services/client';

const participateEvent = withSession(async (eventId: string, data: Record<string, unknown>) => {
  try {
    return await eventService.participate(eventId, data);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Participate event',
      },
    });

    // Needs a re-throw
    throw error;
  }
});

export { participateEvent };

import * as bossClient from '@boss/boss-client';

/**
 * Participate event by event id
 * @param {string} eventId - event ID
 * @param data
 */
export const participate = async (eventId: string, data: Record<string, unknown>) => {
  await bossClient.userEventClient.participateEvent(eventId, data);
};

import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import * as Yup from 'yup';

import { Button, InputField, Modal } from '@boss/ui';

const MagazineCardModal = ({
  onCloseModal: handleCloseModal,
  onSubmit: handleSubmit,
  email,
  isLoading,
}: {
  onCloseModal: (value: boolean) => void;
  onSubmit: (values: { email: string }) => void;
  email?: string;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'magazineCard.modal',
  });
  const formik = useFormik({
    initialValues: {
      email: email ?? '',
      acceptTerms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t('invalidEmail') as string)
        .required(t('emailError') as string),
      acceptTerms: Yup.boolean()
        .oneOf([true], t('termsError') as string)
        .required(t('termsError') as string),
    }),
    onSubmit: () => {
      handleSubmit({ email: formik.values.email });
    },
  });

  return (
    <Modal className="md:max-w-[500px]" onClose={() => handleCloseModal(false)}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <p className="text-lg font-medium">{t('title')}</p>
          <p className="text-sm text-gray-600">{t('description')}</p>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className="flex cursor-pointer items-start gap-2">
              <input
                checked={formik.values.acceptTerms}
                className="mt-1"
                name="acceptTerms"
                onChange={formik.handleChange}
                type="checkbox"
              />
              <span className="text-sm">{t('acceptTerms')}</span>
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className="text-sm text-red-500">{formik.errors.acceptTerms}</div>
            )}
          </div>

          <InputField
            error={formik.touched.email && formik.errors.email ? formik.errors.email : undefined}
            name="email"
            onChange={formik.handleChange}
            placeholder={t('email') as string}
            touched={formik.touched.email}
            value={formik.values.email}
          />
          <Button
            className="w-full"
            disabled={isLoading}
            label={t('subscribe')}
            onClick={() => formik.handleSubmit()}
            type="primary"
          />
        </div>
      </div>
    </Modal>
  );
};

export default MagazineCardModal;

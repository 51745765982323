import * as bossClient from '@boss/boss-client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { IBasket, IBasketDeliveryAddress, IBasketLine, IBasketPatchLine, IBasketUpdateB2C } from './types';

export const getBasket = async (accountId?: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.getBasket(accountId);
export const getBasketById = async (basketId: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.getBasketById(basketId);

export const addBasketLines = async (
  basketId: string,
  basketLines: DeepPartial<IBasketLine>[],
): Promise<IBasket | undefined | null> => await bossClient.basketClient.addBasketLines(basketId, basketLines);

export const deleteBasketLine = async (basketId: string, basketLineId: string): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.deleteBasketLine(basketId, basketLineId);

export const updateBasketLine = async (
  basketId: string,
  basketLine: DeepPartial<IBasketLine>,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.updateBasketLine(basketId, basketLine);

export const updateBasketB2B = async (
  basketId: string,
  basket: DeepPartial<IBasket>,
  locale: string,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.updateBasketB2B(basketId, basket, locale);

const mapAddress = (
  address?: DeepPartial<{
    street: string;
    streetnumber: string;
    postbox: string;
    zipcode: string;
    city: string;
    country: string;
  }>,
) =>
  address && {
    street: address.street,
    streetnumber: address.streetnumber,
    postbox: address.postbox,
    zipcode: address.zipcode,
    city: address.city,
    country: address.country,
  };

export const updateBasketB2C = async (
  basketId: string,
  basket: DeepPartial<IBasketUpdateB2C>,
): Promise<IBasket | undefined | null> => {
  return await bossClient.basketClient.updateBasketB2C(basketId, {
    account: basket.account && {
      firstname: basket.account.firstName,
      lastname: basket.account.lastName,
      name: basket.account.name,
      vatnumber: basket.account.vatNumber,
    },
    contactinfo: basket.contactInfo && {
      phonenumber: basket.contactInfo.phoneNumber,
      email: basket.contactInfo.email,
      language: basket.contactInfo.language,
      preference: basket.contactInfo.preference && {
        onlinecommunication: basket.contactInfo.preference.onlineCommunication,
      },
    },
    delivery: basket.delivery && {
      deliverymethod: basket.delivery.deliverymethod,
      storeid: basket.delivery.storeId,
      address: mapAddress(basket.delivery.address),
    },
    invoice: basket.invoice && {
      address: mapAddress(basket.invoice.address),
    },
    payment: basket.payment && {
      pending: basket.payment.pending,
      payreference: basket.payment.payReference,
    },
  });
};

export const patchBasket = async (
  basketId: string,
  patchLines: IBasketPatchLine[],
): Promise<IBasket | undefined | null> => await bossClient.basketClient.patchBasket(basketId, patchLines);

export const getDeliveryDates = async ({
  storeId,
  modeOfDelivery,
  address,
}: {
  storeId?: string;
  modeOfDelivery: number;
  address?: IBasketDeliveryAddress;
}): Promise<{ dates: string[]; deliverymode: string } | undefined | null> => {
  const data = await bossClient.basketClient.getDeliveryDates({ storeId, modeOfDelivery, address });

  return data ?? undefined;
};

export const addVoucherToBasket = async (
  basketId: string,
  voucher: { type: string; reference: string; webcode?: string },
) => await bossClient.basketClient.addVoucherToBasket(basketId, voucher);

export const deleteVoucherFromBasket = async (basketId: string, voucherId: string) =>
  await bossClient.basketClient.deleteVoucherFromBasket(basketId, voucherId);

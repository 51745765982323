import { ComponentProps, Dispatch, SetStateAction, useEffect } from 'react';

import { types } from '@boss/boss-client';
import { IProduct } from '@boss/services';
import { ColorGroup, IArticle, SimpleColor } from '@boss/types/b2b-b2c';
import { Modal } from '@boss/ui';

import { ColorPickerModal, CrossSellingModal, StockModal, SurfaceCalculatorModal, WishlistModal } from '..';
import { SpecialOrderForm } from '../../components/Form';
import { useColors } from '../../hooks';
import { getArticlesForSurface, getProductColorType, productCardVariant } from '../../utils';
import { ArticleIdsObject } from '../ArticlesTable';

export type Modal = 'color' | 'stock' | 'surfaceCalculator' | 'crossSelling' | 'specialForm' | 'wishlist';

type Props = {
  activeModal: Modal;
  colorGroups: ColorGroup[] | null;
  enableStock: boolean;
  product: IProduct;
  searchedValue: string;
  selectedArticleIdsObject: ArticleIdsObject;
  selectedColor?: SimpleColor;
  selectedColorGroup?: ColorGroup;
  setActiveModal: (modal: Modal | undefined) => void;
  setAmountOfPaintNeeded: (val: number) => void;
  setSavedStore: (store: types.Store) => void;
  setSearchedValue: (val: string) => void;
  setSelectedArticleIdsObject: Dispatch<SetStateAction<ArticleIdsObject>>;
  setSelectedColor: (color: SimpleColor, colorBase?: string) => void;
  setSelectedColorGroup: (colorGroup: ColorGroup) => void;
  calculated: ComponentProps<typeof SurfaceCalculatorModal>['calculated'];
  setCalculated: (val: ComponentProps<typeof SurfaceCalculatorModal>['calculated']) => void;
  articles: IArticle[] | undefined;
};

const ProductDetailPageModals = ({
  activeModal,
  colorGroups,
  calculated,
  enableStock,
  product,
  searchedValue,
  selectedArticleIdsObject,
  selectedColor,
  selectedColorGroup,
  setActiveModal,
  setAmountOfPaintNeeded,
  setSavedStore,
  setSearchedValue,
  setSelectedArticleIdsObject,
  setSelectedColor,
  setSelectedColorGroup,
  setCalculated,
  articles,
}: Props) => {
  const colorType = getProductColorType(product);
  const isPaint = product.type !== 'NON_PAINT';
  const { showColors } = useColors({});
  const enableColors = showColors && isPaint;

  const handleAmountOfPaint = (amountOfPaint: number) => {
    setAmountOfPaintNeeded(amountOfPaint);
    const articlesNeeded = getArticlesForSurface(amountOfPaint, articles);

    if (articlesNeeded) {
      setSelectedArticleIdsObject(articlesNeeded);
    }
  };

  const closeModal = () => setActiveModal(undefined);

  const saveStore = (store: types.Store) => {
    setSavedStore(store);
    closeModal();
  };

  const handleCloseCrossSellingModal = () => {
    setSelectedArticleIdsObject({});
    closeModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setSearchedValue('');
  };

  useEffect(() => {
    if (!selectedColorGroup && colorGroups) {
      setSelectedColorGroup(colorGroups[0]);
    }
  }, [colorGroups, selectedColorGroup, setSelectedColorGroup]);

  return (
    <>
      {activeModal === 'crossSelling' && articles && (
        <CrossSellingModal
          articles={articles}
          crossSellArticles={product.crossSell}
          onClose={handleCloseCrossSellingModal}
          product={product}
          selectedArticleIdsObject={selectedArticleIdsObject}
          selectedColor={selectedColor}
        />
      )}

      {activeModal === 'surfaceCalculator' && (
        <SurfaceCalculatorModal
          articles={articles}
          calculated={calculated}
          consumptionOptions={product.consumptionOptions}
          onClose={closeModal}
          onConfirm={calc => {
            handleAmountOfPaint(calc.totalPaintNeeded);
            setCalculated(calc);
          }}
          product={product}
        />
      )}

      {enableColors && activeModal === 'color' && (
        <ColorPickerModal
          colorGroups={colorGroups}
          colorType={colorType}
          initialColorGroup={selectedColorGroup}
          initialSearchValue={searchedValue}
          onClose={handleCloseModal}
          productId={product.id}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          setSelectedColorGroup={setSelectedColorGroup}
        />
      )}

      {enableStock && activeModal === 'stock' && (
        <StockModal
          articles={articles}
          onClose={handleCloseModal}
          productImage={product.packshot}
          saveStore={saveStore}
          selectedArticleIdsObject={selectedArticleIdsObject}
          variant={productCardVariant}
        />
      )}

      {activeModal === 'wishlist' && (
        <WishlistModal
          onClose={handleCloseModal}
          selectedArticleIdsObject={selectedArticleIdsObject}
          selectedColor={selectedColor}
        />
      )}

      {activeModal === 'specialForm' && (
        <Modal onClose={handleCloseModal}>
          <SpecialOrderForm product={product} />
        </Modal>
      )}
    </>
  );
};

export default ProductDetailPageModals;

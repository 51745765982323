import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';

import { IVoucher } from '@boss/services/client';
import { Accordion, Button, InputField, RadioGroup } from '@boss/ui';

export interface Props {
  className?: string;
  icon?: IconDefinition;
  onSubmit: (values: { type: string; discount: string; webcode: string }) => Promise<boolean>;
  variant?: 'mobile' | 'desktop';
  vouchers?: IVoucher[];
  onRemoveVoucher: (lineid: string) => void;
}

const DiscountButtonStyles = cva('flex flex-col py-2 gap-3 w-full bg-white', {
  variants: {
    variant: {
      mobile: 'rounded-modal',
      desktop: 'rounded-full',
    },
  },
});

const ErrorLabel = ({ error }: { error: string }) => <span className="annotation text-red mt-2">{error}</span>;

const DiscountSection = ({ icon, className, onSubmit, variant = 'mobile', vouchers, onRemoveVoucher }: Props) => {
  const { t } = useTranslation('basket', { keyPrefix: 'discount' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDiscountSection, setShowDiscountSection] = useState(false);
  const { errors, touched, handleSubmit, values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      type: 'giftvoucher',
      discount: '',
      webcode: '',
    },
    validateOnChange: true,
    enableReinitialize: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      discount: Yup.string().required(t('validation.required') ?? ''),
      webcode: Yup.string().when('type', {
        is: 'giftvoucher',
        then: schema => schema.required(t('validation.required') ?? ''),
        otherwise: schema => schema.optional(),
      }),
    }),
    onSubmit: async (values, { setFieldValue, setFieldError }) => {
      setIsSubmitting(true);
      try {
        const response = await onSubmit(values);

        if (response) {
          setFieldValue('discount', '');
          setFieldValue('webcode', '');
          resetForm();
          setShowDiscountSection(false);
        } else {
          setFieldError('discount', t('validation.invalid') ?? '');
        }
      } catch (error) {
        setFieldError('discount', t('validation.invalid') ?? '');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const options = [
    { label: t('discountOptions.gift'), value: 'giftvoucher' },
    { label: t('discountOptions.voucher'), value: 'discountvoucher' },
  ];

  const handleTypeChange = (value: string) => {
    setFieldValue('type', value);
  };

  return (
    <div className={twMerge(DiscountButtonStyles({ variant }), className)}>
      {vouchers && !!vouchers.length && (
        <div className="flex flex-col gap-3">
          <div className="font-bold">{t('activeDiscounts')}</div>
          {vouchers.map(voucher => (
            <div className="flex items-center justify-between rounded-lg bg-gray-100 p-4" key={voucher.lineid}>
              <div className="flex flex-col">
                <span className="font-medium">{voucher.reference}</span>
                <span className="text-sm text-gray-600">€ {voucher.amount}</span>
              </div>
              <button
                className="text-red-500 transition-colors hover:text-red-700"
                onClick={() => onRemoveVoucher?.(voucher.lineid)}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </div>
          ))}
        </div>
      )}
      <div>
        <Accordion
          hideDivider
          iconClassName="hidden"
          isOpen={showDiscountSection}
          setExpanded={setShowDiscountSection}
          title={
            <div className="items-bottom flex">
              {icon && <FontAwesomeIcon className="text-blue mr-3" icon={icon} size="sm" />}
              <div>
                <p className="small text-blue font-bold">{t('addDiscount')}</p>
              </div>
            </div>
          }
          wrapperClassName="w-full"
        >
          <div className="flex w-full flex-col gap-3">
            <RadioGroup
              className="flex flex-row gap-4"
              name="type"
              onChange={handleTypeChange}
              options={options}
              value={values.type}
            />

            <InputField
              error={errors.discount && touched.discount ? errors.discount : undefined}
              inputClassName="w-full"
              name="discount"
              onChange={e => setFieldValue('discount', e.target.value)}
              placeholder={t('inputPlaceholder.discountCode') ?? ''}
              value={values.discount}
            >
              {errors.discount && touched.discount ? <ErrorLabel error={errors.discount} /> : null}
            </InputField>
            {values.type === 'giftvoucher' && (
              <InputField
                error={errors.webcode && touched.webcode ? errors.webcode : undefined}
                name="webcode"
                onChange={e => setFieldValue('webcode', e.target.value)}
                placeholder={t('inputPlaceholder.webCode') ?? ''}
                value={values.webcode}
              >
                {errors.webcode && touched.webcode ? <ErrorLabel error={errors.webcode} /> : null}
              </InputField>
            )}
            <Button disabled={isSubmitting} label={t('applyDiscount')} onClick={() => handleSubmit()} type="primary" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default DiscountSection;

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { toast } from 'sonner';
// eslint-disable-next-line import/named
import { v4 as uuid } from 'uuid';

import {
  IBasket,
  IBasketDeliveryAddress,
  IBasketLine,
  IBasketPatchLine,
  IBasketUpdateB2C,
} from '@boss/services/client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import {
  addBasketLines,
  addVoucherToBasket,
  deleteBasketLine,
  deleteVoucherFromBasket,
  getBasket,
  getBasketById,
  getDeliveryDates,
  patchBasket,
  updateBasketB2B,
  updateBasketB2C,
  updateBasketLine,
} from './connector';
import { useAnonymousBasket, useOptimistic, useProfile } from '../../hooks';
import { isB2b, mapNewBasketLineToMockBasketLine } from '../../utils';

import { basketKeys } from '.';

const useGetBasket = (basketId?: string, enabled = true) => {
  const { t } = useTranslation('common');
  const { accountId } = useProfile();
  const { setAnonymousBasketId, anonymousBasketId } = useAnonymousBasket();

  const _basketId = basketId || anonymousBasketId;

  return useQuery<IBasket>({
    queryKey: basketKeys.basket(accountId, basketId),
    queryFn: async () => {
      if (_basketId) {
        return await getBasketById(_basketId);
      }

      if (accountId) {
        return await getBasket(accountId);
      }

      const basket = await getBasket();

      if (basket?.id) {
        setAnonymousBasketId(basket.id);
      }

      return basket;
    },
    enabled: isB2b ? !!accountId && enabled : enabled,
    meta: {
      errorMessage: t('toast.fetchBasket.error.title'),
    },
  });
};

const useGetBasketById = () => {
  const { t } = useTranslation('common');

  return useMutation({
    mutationFn: async ({ basketId }: { basketId: string }) => {
      return await getBasketById(basketId);
    },
    onError: () => {
      toast.error(t('toast.fetchBasketById.error.title'));
    },
  });
};

/**
 * Verify naively update the basket to increase the amount of lines present in the basket when the given newLine skuid doesn't exist yet
 **/
const getOptimisticBasketLines = (
  lines: DeepPartial<IBasketLine>[],
  newLines: DeepPartial<IBasketLine>[],
): DeepPartial<IBasketLine>[] => {
  const filtedNewLines = newLines.filter(newLine => !lines.some(line => line?.item?.skuid === newLine?.item?.skuid));

  // Adding of a mock basketline to ensure that when no lines are present, a new one is added temporarily until the basket gets refetched
  return [...lines, ...filtedNewLines.map(mapNewBasketLineToMockBasketLine)];
};

/**
 * useMutation implementation to add a new basket line
 *
 * @returns {object} An object with a mutate function and mutation status
 */
const useAddBasketLine = () => {
  const { t } = useTranslation('common');
  const { onMutateHelper } = useOptimistic();
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({
      basketId,
      newBasketLines,
    }: {
      basketId: string;
      newBasketLines: DeepPartial<IBasketLine>[];
    }) => {
      return addBasketLines(basketId, newBasketLines);
    },
    onMutate: async ({ newBasketLines }) => {
      const previousData: IBasket | undefined = queryClient.getQueryData(queryKey);
      const previousLines = previousData?.lines ?? [];
      const newBasket = { ...previousData, lines: getOptimisticBasketLines(previousLines, newBasketLines) };

      return onMutateHelper(queryKey, previousData, newBasket);
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);
      toast.error(t('toast.addBasketLine.error.title'));
    },
    onSuccess: (data, params) => {
      queryClient.setQueryData(queryKey, data);

      if (isB2b) {
        toast.success(t('toast.addBasketLine.success.title', { count: params.newBasketLines.length }));
      }
    },
  });
};

/**
 * useMutation implementation to add a new basket line
 *
 * @param fallbackUrl When the last line of the basket is deleted, a fallback url can be used for re-routing
 * @returns {object} An object with a mutate function and mutation status
 */
const useDeleteBasketLine = (fallbackUrl?: string) => {
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const router = useRouter();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({ basketId, basketLineId }: { basketId: string; basketLineId: string }) => {
      return deleteBasketLine(basketId, basketLineId);
    },
    onSuccess: data => {
      if (fallbackUrl && data?.lines.length === 0) {
        router.push(fallbackUrl);
      }
      queryClient.setQueryData(queryKey, data);
    },
  });
};

/**
 * useMutation implementation to update a basket line
 *
 * @returns {object} An object with a mutate function and mutation status
 */
const useUpdateBasketLine = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  const latestRequestRef = useRef<string | null>(null);

  return useMutation({
    mutationFn: async ({ basketId, basketLine }: { basketId: string; basketLine: DeepPartial<IBasketLine> }) => {
      return updateBasketLine(basketId, basketLine);
    },
    onMutate: async ({ basketLine }) => {
      const requestId = uuid();

      latestRequestRef.current = requestId;
      const previousData: IBasket | undefined = queryClient.getQueryData(queryKey);
      const newBasket = produce(previousData, draft => {
        if (draft) {
          (draft.lines as DeepPartial<IBasketLine>[]) = draft.lines.map(line => {
            if (line.id === basketLine.id) {
              return basketLine;
            }
            return line;
          });
        }
      });

      queryClient.setQueryData(queryKey, newBasket);

      return { previousData, requestId, newBasket };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(queryKey, context?.previousData);
      toast.error(t('toast.updateBasketLine.error.title'));
    },
    onSuccess: (data, _, context) => {
      if (context?.requestId === latestRequestRef.current) {
        queryClient.setQueryData(queryKey, data);
      }
    },
  });
};

/**
 * useMutation implemntation to update the b2b basket
 * @returns {object} An object with a mutate function and mutation status
 */
const useUpdateBasketB2B = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({ basketId, basket }: { basketId: string; basket: DeepPartial<IBasket> }) => {
      return updateBasketB2B(basketId, basket, locale as string);
    },
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: (error: Error) => {
      toast.error(t('toast.updateBasket.error.title'));
      throw error;
    },
  });
};

/**
 * useMutation implementation to update the b2c basket
 */
const useUpdateBasketB2C = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({ basketId, basket }: { basketId: string; basket: DeepPartial<IBasketUpdateB2C> }) => {
      return updateBasketB2C(basketId, basket);
    },
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: () => {
      toast.error(t('toast.updateBasket.error.title'));
    },
  });
};

/**
 * useMutation implementation to patch the basket
 */
const usePatchBasket = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({ basketId, patchLines }: { basketId: string; patchLines: IBasketPatchLine[] }) => {
      return patchBasket(basketId, patchLines);
    },
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: () => {
      toast.error(t('toast.patchBasket.error.title'));
    },
  });
};

/**
 * useMutation implementation to fetch delivery dates for the basket
 * @param modeOfDelivery
 * @returns
 */
const useGetDeliveryDates = () => {
  const { t } = useTranslation('common');

  return useMutation({
    mutationFn: async ({
      modeOfDelivery,
      storeId,
      address,
    }: {
      storeId?: string;
      modeOfDelivery: number;
      address?: IBasketDeliveryAddress;
    }) =>
      await getDeliveryDates({
        modeOfDelivery,
        storeId,
        address,
      }),
    meta: {
      errorMessage: t('toast.fetchDeliveryDates.error.title'),
    },
  });
};

/**
 * useMutation implementation to add a voucher to the basket
 */
const useAddVoucherToBasket = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({
      basketId,
      voucher,
    }: {
      basketId: string;
      voucher: { type: string; reference: string; webcode?: string };
    }) => {
      return addVoucherToBasket(basketId, voucher);
    },
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
      toast.success(t('toast.addVoucherToBasket.success.title'));
    },
    onError: () => {
      toast.error(t('toast.addVoucherToBasket.error.title'));
    },
  });
};

/**
 * useMutation implementation to delete a voucher from the basket
 */
const useDeleteVoucherFromBasket = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const queryKey = basketKeys.basket(accountId);

  return useMutation({
    mutationFn: async ({ basketId, voucherId }: { basketId: string; voucherId: string }) => {
      return deleteVoucherFromBasket(basketId, voucherId);
    },
    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
      toast.success(t('toast.deleteVoucherFromBasket.success.title'));
    },
    onError: () => {
      toast.error(t('toast.deleteVoucherFromBasket.error.title'));
    },
  });
};

export {
  useAddVoucherToBasket,
  useDeleteVoucherFromBasket,
  useAddBasketLine,
  useDeleteBasketLine,
  useGetBasket,
  useGetBasketById,
  useUpdateBasketLine,
  useGetDeliveryDates,
  useUpdateBasketB2B,
  useUpdateBasketB2C,
  usePatchBasket,
};

/* eslint-disable */
const Register = () => {
  return (
    <>
      <div className="buttons"></div>
      <div className="intro">
        <p>Geef de volgende gegevens op.</p>
      </div>
      <form id="attributeVerification" onSubmit={e => e.preventDefault()}>
        <div aria-hidden="true" className="error pageLevel" id="passwordEntryMismatch" style={{ display: 'none' }}>
          De wachtwoordinvoervelden komen niet overeen. Voer in beide velden hetzelfde wachtwoord in en probeer het
          opnieuw.
        </div>
        <div aria-hidden="true" className="error pageLevel" id="requiredFieldMissing" style={{ display: 'none' }}>
          Er ontbreekt een vereist veld. Vul alle vereiste velden in en probeer het opnieuw.
        </div>
        <div aria-hidden="true" className="error pageLevel" id="fieldIncorrect" style={{ display: 'none' }}>
          Een of meer velden zijn onjuist ingevuld. Controleer de ingevoerde gegevens en probeer het opnieuw.
        </div>
        <div
          aria-hidden="true"
          className="error pageLevel"
          id="claimVerificationServerError"
          style={{ display: 'none' }}
        ></div>
        <div className="attr" id="attributeList">
          <ul>
            <li className="TextBox">
              <div className="attrEntry">
                <label htmlFor="email">Email Address</label>
                <input
                  autoFocus
                  className="textInput"
                  id="email"
                  required
                  type="text"
                  placeholder="Email Address"
                  // pattern="^[a-zA-Z0-9!#$%&'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$"
                  title="Email address that can be used to contact you."
                />
                <a
                  className="helpLink tiny"
                  data-help="Email address that can be used to contact you."
                  href="javascript:void(0)"
                >
                  Wat is dit?
                </a>
                <div className="buttons verify" data-claim_id="email">
                  <div
                    aria-hidden="true"
                    aria-label="Een ogenblik geduld..."
                    className="working"
                    id="email_ver_wait"
                    style={{ display: 'none' }}
                  ></div>
                  <label aria-hidden="true" htmlFor="email_ver_input" style={{ display: 'none' }}>
                    Verificatiecode
                  </label>
                  <input
                    aria-hidden="true"
                    autoComplete="off"
                    className="verifyInput"
                    id="email_ver_input"
                    placeholder="Verificatiecode"
                    style={{ display: 'none' }}
                    type="text"
                  />
                  <button
                    aria-hidden="false"
                    aria-label="Verificatiecode verzenden"
                    className="sendButton"
                    id="email_ver_but_send"
                    style={{ display: 'inline' }}
                    type="button"
                  >
                    Verificatiecode verzenden
                  </button>
                  <button
                    aria-hidden="true"
                    aria-label="Code verifiëren"
                    className="verifyButton"
                    id="email_ver_but_verify"
                    style={{ display: 'none' }}
                    type="button"
                  >
                    Code verifiëren
                  </button>
                  <button
                    aria-hidden="true"
                    aria-label="Nieuwe code verzenden"
                    className="sendButton"
                    id="email_ver_but_resend"
                    style={{ display: 'none' }}
                    type="button"
                  >
                    Nieuwe code verzenden
                  </button>
                  <button
                    aria-hidden="true"
                    aria-label="E-mailadres wijzigen"
                    className="editButton"
                    id="email_ver_but_edit"
                    style={{ display: 'none' }}
                    type="button"
                  >
                    E-mailadres wijzigen
                  </button>
                  <button
                    aria-label="Standaardinstelling"
                    className="defaultButton"
                    id="email_ver_but_default"
                    style={{ display: 'none' }}
                    type="button"
                  >
                    Standaardinstelling
                  </button>
                </div>
              </div>
            </li>
            <li className="Password">
              <div className="attrEntry">
                <label htmlFor="newPassword">New Password</label>
                <input
                  className="textInput"
                  id="newPassword"
                  required
                  type="password"
                  placeholder="New Password"
                  // pattern="^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,16}$"
                  title="Enter new password"
                />
                <a className="helpLink tiny" data-help="Enter new password" href="javascript:void(0)">
                  Wat is dit?
                </a>
              </div>
            </li>
            <li className="Password">
              <div className="attrEntry">
                <label htmlFor="reenterPassword">Confirm New Password</label>
                <input
                  className="textInput"
                  id="reenterPassword"
                  required
                  type="password"
                  placeholder="Confirm New Password"
                  // pattern="^((?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])|(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9])|(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z0-9]))([A-Za-z\d@#$%^&*\-_+=[\]{}|\\:',?/`~"();!]|\.(?!@)){8,16}$"
                  title="Confirm new password"
                />
                <a className="helpLink tiny" data-help="Confirm new password" href="javascript:void(0)">
                  Wat is dit?
                </a>
              </div>
            </li>
            <li className="TextBox">
              <div className="attrEntry">
                <label htmlFor="givenName">Given Name</label>
                <input
                  className="textInput"
                  id="givenName"
                  placeholder="Given Name"
                  title="Your given name (also known as first name)."
                  type="text"
                />
                <a
                  className="helpLink tiny"
                  data-help="Your given name (also known as first name)."
                  href="javascript:void(0)"
                >
                  Wat is dit?
                </a>
              </div>
            </li>
            <li className="TextBox">
              <div className="attrEntry">
                <label htmlFor="surname">Surname</label>
                <input
                  className="textInput"
                  id="surname"
                  placeholder="Surname"
                  title="Your surname (also known as family name or last name)."
                  type="text"
                />
                <a
                  className="helpLink tiny"
                  data-help="Your surname (also known as family name or last name)."
                  href="javascript:void(0)"
                >
                  Wat is dit?
                </a>
              </div>
            </li>
            <li className="TextBox">
              <div className="attrEntry">
                <label htmlFor="extension_VatNumber">VAT number</label>
                <input
                  aria-label="VAT number"
                  className="textInput"
                  id="extension_VatNumber"
                  placeholder="VAT number"
                  type="text"
                />
                <a className="helpLink tiny" href="javascript:void(0)">
                  Wat is dit?
                </a>
              </div>
            </li>
            <li className="CheckboxMultiSelect">
              <div className="attrEntry">
                <label htmlFor="extension_TermsAndConditions">
                  <input
                    aria-labelledby="extension_TermsAndConditions_label extension_TermsAndConditions_AgreeToTermsOfUseConsentYes_option"
                    id="extension_TermsAndConditions_AgreeToTermsOfUseConsentYes"
                    name="extension_TermsAndConditions"
                    type="checkbox"
                    value="AgreeToTermsOfUseConsentYes"
                  />
                  <span>I agree to the Terms Of Service</span>
                </label>
                <a className="helpLink tiny" href="javascript:void(0)">
                  Wat is dit?
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons">
          <button aria-disabled="true" id="continue" type="submit">
            Doorgaan
          </button>
          <button formNoValidate id="cancel">
            Annuleren
          </button>
        </div>
        <div className="verifying-modal">
          <div id="verifying_blurb"></div>
        </div>
      </form>
    </>
  );
};

export default Register;

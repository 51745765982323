import { BLOCKS, Document } from '@contentful/rich-text-types';
import { TFunction } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { getAlgolia, getSearchIndexName } from '@boss/algolia-client';
import {
  CHANNEL,
  actionPageSlugs,
  categoryPageSlugs,
  colorPageSlugs,
  colorToolSlugs,
  contenfulOverviewTypes,
  eventPageSlugsB2B,
  eventPageSlugsB2C,
  inspirationPageSlugs,
  overviewPageSlugs,
  overviewTypes,
  paintguidePageSlugs,
  stepByStepPageSlugs,
  storePageSlugs,
} from '@boss/constants/b2b-b2c';
import { type RenderOptions, RichText } from '@boss/rich-text';
import { ICategoryPromotion, INavigation, INavigationItem, IProduct } from '@boss/services';
import type {
  ActionCardFields,
  CampaignFields,
  CardWrapperFields,
  Color,
  ContactInformationFields,
  ContentPageFields,
  CtaFields,
  FaqFields,
  FormFields,
  ImageFields,
  InspirationImageFields,
  MagazineFields,
  Navigation,
  NavigationItem,
  OverviewFields,
  OverviewPageFields,
  PromotionCtaFields,
  QuoteFields,
  RichTextFields,
  SectionFields,
  ServicePointFields,
  Theme,
  VideoFields,
} from '@boss/types/b2b-b2c';
import { Nullable } from '@boss/types/b2b-b2c';
import { Paragraph, icons } from '@boss/ui';
import { getLink, getPagePathByEntry, getValuable } from '@boss/utils';

import { FACET_FILTERS_BASE } from '../../../constants';
import {
  eventConfig,
  getSectionBgColor,
  inspirationGridVariant,
  isB2b,
  promotionCardVariant,
  sectionQuoteProps,
} from '../../../utils';
import { mapNextToContentfulLocale } from '../../../utils/localeMapper';
import ComponentMapper from '../ComponentMapper';
import SectionMapper from '../SectionMapper';

const getHref = (entry: CtaFields, locale: string) => {
  const { internalLink, externalLink, anchor, filteredEventOverviewLink } = entry;

  if (filteredEventOverviewLink) {
    const filters = {
      type: filteredEventOverviewLink.type,
      locationId: filteredEventOverviewLink.locationid,
    };

    const queryString = Object.entries(filters)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    return `${isB2b ? eventPageSlugsB2B[locale] : eventPageSlugsB2C[locale]}?${queryString}`;
  }

  if (internalLink) {
    const internalLinkPath = getPagePathByEntry(internalLink, locale);
    const anchorLink = anchor?.anchorLinkText ? `#${anchor.anchorLinkText}` : '';

    return `${internalLinkPath}${anchorLink}`;
  } else {
    return externalLink ?? '';
  }
};

export const getPromotionCtaProps = (entry: PromotionCtaFields, theme: Theme, locale: string) => {
  return {
    image: entry.image,
    icon: entry.icon ? icons.getPreloadedIcon(entry.icon) : null,
    text: entry.text ? (
      <RichText
        ComponentMapper={ComponentMapper}
        content={entry?.text}
        mapperOptions={{ theme, locale }}
        renderOptions={{ noWrapper: true, noParagraph: true }}
      />
    ) : null,
    link: {
      href: getHref(entry.cta, locale),
      label: entry.cta.label,
    },
    theme,
  };
};

export const getSectionProps = (
  entry: SectionFields,
  theme: Theme,
  locale: string,
  titleClassName?: unknown,
  products?: IProduct[],
  colors?: Color[],
) => {
  const getAdditionalClassName = () => {
    if (entry.backgroundColor !== 'apply-theme') {
      return null;
    }

    const color = getSectionBgColor(theme);

    return `relative break-before break-after ${color} before:-z-base after:-z-base`;
  };

  const className = getAdditionalClassName();

  const content = (
    <SectionMapper
      className={className}
      colors={colors}
      entry={entry}
      locale={locale}
      products={products}
      theme={theme}
    />
  );
  const titleClass = !!titleClassName && typeof titleClassName === 'string' ? titleClassName : '';

  return {
    id: entry.id,
    anchorId: entry.anchorLinkText,
    displayAs: entry.displaySectionAs,
    title: entry.title ? (
      <RichText
        ComponentMapper={ComponentMapper}
        content={entry.title}
        mapperOptions={{ theme, locale }}
        renderNode={{
          [BLOCKS.PARAGRAPH]: (_, children) => <h2 className={twMerge('mb-4', titleClass)}>{children}</h2>,
        }}
      />
    ) : null,
    description: entry.description ? <ComponentMapper entry={entry.description} locale={locale} theme={theme} /> : null,
    content,
    className,
    ctaPosition: entry.ctaPosition,
    cta: entry.cta ? <ComponentMapper entry={entry.cta} locale={locale} theme={theme} /> : null,
  };
};

export const getRichTextProps = (entry: RichTextFields, theme: Theme, locale: string) => {
  return {
    content: entry.content,
    ComponentMapper,
    mapperOptions: {
      theme,
      locale,
    },
  };
};

const getIconPosition = (iconPosition: 'prefixed' | 'suffixed'): 'left' | 'right' | 'both' | undefined => {
  switch (iconPosition) {
    case 'prefixed':
      return 'left';
    case 'suffixed':
      return 'right';
    default:
      return 'left';
  }
};

export const getCtaProps = (entry: CtaFields, theme: Theme, locale: string, inline?: boolean) => {
  const { label, icon, iconPosition } = entry;

  return {
    body: label,
    href: getHref(entry, locale),
    icon: icon,
    iconPosition: getIconPosition(iconPosition),
    type: 'enriched' as 'link' | 'inline' | 'enriched' | 'horizontal' | 'vertical' | null | undefined,
    fileToDownload: entry.fileToDownload,
  };
};

export const getButtonProps = (entry: CtaFields, theme: Theme, locale: string, inline?: boolean) => {
  const { label, icon, ctaVariation, alternativeText, colorfulButtonVariation, iconPosition } = entry;

  return {
    alternativeText: alternativeText ? (
      <RichText
        ComponentMapper={ComponentMapper}
        content={alternativeText}
        mapperOptions={{ theme, locale }}
        renderNode={{
          [BLOCKS.PARAGRAPH]: (_, children) => <Paragraph className="my-0 py-0">{children}</Paragraph>,
        }}
        renderOptions={{ noWrapper: true }}
      />
    ) : null,
    href: getHref(entry, locale),
    label,
    type: ctaVariation.toLowerCase() as 'primary' | 'secondary' | 'tertiary',
    icon: icon && icons.getPreloadedIcon(icon),
    iconPosition: getIconPosition(iconPosition),
    color: colorfulButtonVariation,
    anchorClassName: inline ? 'inline-block' : 'w-fit',
    fileToDownload: entry.fileToDownload?.file?.url,
  };
};

export const getButtonPropsSimple = (entry: CtaFields, locale: string) => {
  return {
    id: entry.id,
    href: getHref(entry, locale),
    label: entry.label,
  };
};

export const getImageProps = (entry: ImageFields) => {
  return {
    image: entry?.image,
    alt: entry?.altText,
    className: CHANNEL === 'colora' ? 'rounded-l-30 rounded-rl-30 lg:rounded-l-50 lg:rounded-tr-50' : '',
    contain: entry?.containImage,
  };
};

export const getFaqProps = (entry: FaqFields, theme: Theme, locale: string) => {
  const { question, answer } = entry;

  return {
    title: (
      <RichText
        ComponentMapper={ComponentMapper}
        content={question}
        mapperOptions={{ theme, locale }}
        renderNode={{
          [BLOCKS.PARAGRAPH]: (_, children) => <h5 className="h4 mb-4">{children}</h5>,
        }}
      />
    ),
    children: <ComponentMapper entry={answer} locale={locale} theme={theme} />,
    iconClassName: 'md:text-blue',
    hasVideo: false,
  };
};

export const getVideoProps = (entry: VideoFields) => {
  return {
    src: entry.externalVideoUrl,
    thumbnail: entry.thumbnail,
    alternativeText: entry.alternativeText,
  };
};

export const getPageCardProps = (entry: ContentPageFields | OverviewPageFields, locale: string) => {
  const usedImage = entry.mainImage ?? entry.ogImage;

  return {
    title: entry.pageTitle,
    imageSrc: usedImage?.image?.file?.url,
    imageAlt: usedImage?.altText,
    channel: entry.channel,
    slug: 'slug' in entry ? getPagePathByEntry(entry, locale) : getSlugByEntry(entry, locale),
  };
};

export const getInspirationImageProps = (theme: Theme, locale: string, getTranslation: TFunction) => {
  const { searchIndex } = getAlgolia({
    isShared: true,
    indexName: getSearchIndexName(
      process.env.NEXT_PUBLIC_ALGOLIA_IMAGES_INDEX ?? '',
      mapNextToContentfulLocale(locale),
    ),
  });
  const hitsPerPage = 20;
  const baseSearchOptions = {
    hitsPerPage,
    facets: ['*'],
    facetFilters: `channel:“${CHANNEL}“,${FACET_FILTERS_BASE}`,
  };

  const facetsToFilterObj = (
    facets: Nullable<Record<string, Record<string, number>>>,
    facetsToRender?: readonly string[],
  ) => {
    if (!facets) {
      return [];
    }

    const filters = [];

    for (const [filterName, filterValues] of Object.entries(facets)) {
      if (facetsToRender?.includes(filterName)) {
        filters.push({
          filterName,
          filterLabel: getTranslation(`filters.${filterName}`),
          filterValues: Object.keys(filterValues).map(value => ({
            value: value,
            label: getTranslation(`filters.${value}`),
          })),
        });
      }
    }

    return filters;
  };

  return {
    textRenderer: (text: Document, renderOptions?: RenderOptions) => (
      <RichText content={text} mapperOptions={{ theme, locale }} renderOptions={renderOptions} />
    ),
    initialContentFetcher: searchIndex
      ? async (filters = '') => {
          const {
            hits: images,
            facets,
            renderingContent,
          } = await searchIndex.search<InspirationImageFields>('', {
            ...baseSearchOptions,
            filters,
          });

          const facetsToRender = renderingContent?.facetOrdering?.facets?.order;
          const response = await fetch(`/api/inspiration-cta?preview=false&locale=${locale}`);
          const cta = (await response.json()) as unknown as PromotionCtaFields;
          const filterObj = facetsToFilterObj(facets, facetsToRender);

          return {
            images: images.filter(img => img.image.image?.file?.url),
            cta,
            filters: filterObj,
          };
        }
      : null,
    infiniteImageFetcher: searchIndex
      ? async (skip: number, filters = '') => {
          const { hits: images } = await searchIndex.search<InspirationImageFields>('', {
            ...baseSearchOptions,
            filters,
            page: Math.ceil(skip / hitsPerPage),
          });

          return images.filter(img => img.image.image?.file?.url);
        }
      : null,
    translations: {
      filtersMobile: getTranslation('filtersMobile'),
      filtersTitle: getTranslation('filtersTitle'),
      showResults: getTranslation('showResults'),
      clearButtonText: getTranslation('clearButtonText'),
      // The getTranslation function retuns the object as type object. Accessing this type gives a signature of any, by recasting we gain more control over the type.
      filterLabels: getTranslation('filters', { returnObjects: true }) as Record<string, unknown>,
    },
    locale,
    theme,
    variant: inspirationGridVariant,
  };
};

export const getQuoteProps = (entry: QuoteFields) => ({
  quote: entry.quote,
  company: entry.company,
  author: entry.author,
  variant: sectionQuoteProps.variant,
});

export const getQuoteCarouselProps = (entry: QuoteFields) => ({
  quote: entry.quote,
  company: entry.company,
  author: entry.author,
  image: entry.image,
});

export const getServicePromotionCta = (data: Nullable<ServicePointFields>) => {
  return data?.servicesSection?.find(
    (promotionCta): promotionCta is PromotionCtaFields => promotionCta.__typename === 'promotionCta',
  );
};

export const getServiceLinks = (data: Nullable<ServicePointFields>, theme: Theme, locale: string) => {
  return data?.servicesSection
    ?.filter((item): item is CtaFields => item?.__typename === 'cta')
    .map(item => getButtonProps(item, theme, locale));
};

export const getSpecialMessage = (entry: Document, theme: Theme, locale: string) => {
  return {
    text: entry ? (
      <RichText
        ComponentMapper={ComponentMapper}
        content={entry}
        mapperOptions={{ theme, locale }}
        renderOptions={{ noWrapper: true, noParagraph: true }}
      />
    ) : null,
  };
};

export const getCampaignProps = (entry: CampaignFields, theme: Theme, locale: string) => ({
  ...entry,
  imageUrl: entry.image?.image.file.url,
  callToAction: entry.callToAction ? (
    <ComponentMapper entry={entry.callToAction} locale={locale} theme={theme} />
  ) : undefined,
  text: <RichText className="[&>*]:m-0 [&>*]:w-fit" content={entry.text} mapperOptions={{ theme, locale }} />,
});

const getSlugByEntry = (entry: OverviewPageFields, locale: string) => {
  const slugMapping = {
    'Store overview': storePageSlugs,
    'Color overview': colorPageSlugs,
    'Step by step plans': stepByStepPageSlugs,
    'Inspiration overview': inspirationPageSlugs,
    'Category overview': categoryPageSlugs,
    'Event overview': eventConfig.eventPageSlugs,
    'Paint guide': paintguidePageSlugs,
    'Color tool': colorToolSlugs,
    [contenfulOverviewTypes[overviewTypes.INSIDE_VIEWER]]: overviewPageSlugs[overviewTypes.INSIDE_VIEWER],
    [contenfulOverviewTypes[overviewTypes.TESTIMONIAL]]: overviewPageSlugs[overviewTypes.TESTIMONIAL],
    [contenfulOverviewTypes[overviewTypes.PROFESSIONAL_TIPS]]: overviewPageSlugs[overviewTypes.PROFESSIONAL_TIPS],
    [contenfulOverviewTypes[overviewTypes.PAINTING_TIPS]]: overviewPageSlugs[overviewTypes.PAINTING_TIPS],
    [contenfulOverviewTypes[overviewTypes.COLOR_TIPS]]: overviewPageSlugs[overviewTypes.COLOR_TIPS],
  };

  return slugMapping[entry.type]?.[locale] ?? '';
};

export const getContentTileProps = (entry: ContentPageFields | OverviewPageFields, locale: string) => {
  return {
    imageUrl: entry.mainImage?.image.file.url,
    imageAlt: entry.mainImage?.altText ?? 'content-tile-image',
    title: entry.pageTitle,
    description: entry.cardDescription ?? '',
    // OverviewPages don't have a slug, we fetch it by comparing the types and giving back the required slug by locale
    slug: getPagePathByEntry(entry, locale),
  };
};

export const getCardWrapperProps = (entry: CardWrapperFields, locale: string) => {
  return {
    ...getContentTileProps(entry.content, locale),
    variant: entry.variation,
    style: entry.cardBackgroundColor ? { backgroundColor: entry.cardBackgroundColor } : {},
  };
};

const getUrl = (navItem: INavigationItem, locale: string) => {
  if (navItem.internalPageLink) {
    const pagePath = getLink(navItem.internalPageLink, locale);
    const anchor = navItem.internalPageLink.anchor ? `#${navItem.internalPageLink.anchor}` : '';

    return `${pagePath}${anchor}`;
  }

  if (navItem.externalLink) {
    return navItem.externalLink;
  }

  return null;
};

const mapNavLinks = (links: Nullable<INavigationItem[]>, locale: string): Nullable<NavigationItem[]> =>
  links?.map(link => {
    const iconName = link.icon ?? link.internalPageLink?.icon;
    const label = link.label ?? link.internalPageLink?.label;

    return getValuable({
      href: getUrl(link, locale),
      icon: iconName && icons.getPreloadedIcon(iconName),
      label: label,
      id: link.id,
      image: link.image,
      childNavigationItems: mapNavLinks(link.childNavigationItems, locale),
      highlightedChildNavigationItems: mapNavLinks(link.highlightedChildNavigationItems, locale),
    });
  });

export const getNavigationProps = (navigation: INavigation, locale: string): Navigation =>
  getValuable({
    id: navigation.id,
    channel: navigation.channel,
    copyrightOrganisation: navigation.copyrightOrganisation,
    footerNavigationItems: mapNavLinks(navigation.footerNavigationItems, locale),
    headerExtraBarItems: navigation.headerExtraBarItems,
    headerLogo: navigation.headerLogo,
    footerLogo: navigation.footerLogo,
    headerNavigationItems: mapNavLinks(navigation.headerNavigationItems, locale),
    highlightedCta: mapNavLinks(navigation.highlightedCta, locale),
    legalLinks: mapNavLinks(navigation.legalLinks, locale),
    paymentMethods: navigation.paymentMethods,
    secondaryCtas: mapNavLinks(navigation.secondaryCtas, locale),
    socialLinks: mapNavLinks(navigation.socialLinks, locale),
  });

export const mapPromotionCard = (promotion: ICategoryPromotion, locale: string) => ({
  text: <RichText content={promotion.text} mapperOptions={{ locale }} />,
  imageSrc: promotion.image?.image?.file?.url,
  imageAlt: promotion.image?.altText ?? '',
  href: getHref(promotion.cta, locale),
  variant: promotionCardVariant,
});

export const getFormMapperProps = (entry: FormFields) => ({
  channel: entry.channel,
  bossPaintsFormType: entry.bossPaintsFormType,
  coloraFormType: entry.coloraFormType,
  typeformId: entry.typeformId,
});

export const mapFullCta = (entry: OverviewFields | ContentPageFields, locale: string) => {
  if (entry.__typename === 'overviewPage') {
    return {
      body: entry.metaDescription,
      href: getLink(entry, locale),
      title: entry.pageTitle,
    };
  }

  if (entry.__typename === 'contentPage') {
    return {
      body: entry.metaDescription,
      href: getLink(entry, locale),
      title: entry.pageTitle,
    };
  }

  return null;
};

export const getContactInfo = (entry: ContactInformationFields, locale: string) => ({
  phone: entry.phoneNumber,
  email: entry.email,
  address: entry.address,
  ctas: entry.ctas.map(cta => getButtonPropsSimple(cta, locale)),
});

export const getActionCardProps = (entry: ActionCardFields, locale: string) => {
  const { filters, title, description, image, ctaLabel, internalPageLink, externalLink } = entry;

  let href = '';

  if (filters) {
    const filterString = filters?.map(filter => `${filter.key}:"${filter.value}"`).join(',');

    href = `${actionPageSlugs[locale]}?filters=${filterString}&title=${title}`;
  } else if (internalPageLink) {
    href = getPagePathByEntry(internalPageLink, locale);
  } else if (externalLink) {
    href = externalLink;
  }

  return {
    title,
    description: description ? <RichText content={description} mapperOptions={{ locale }} /> : null,
    image,
    href,
    buttonText: ctaLabel,
  };
};

export const getMagazineCardProps = (entry: MagazineFields, locale: string) => ({
  annotation: entry.edition,
  image: entry.thumbnail,
  previewUrl: entry.previewFile?.file?.url,
});

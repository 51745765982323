import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useState } from 'react';
import { useHits, usePagination } from 'react-instantsearch';

import { paintguidePageSlugs, trackEvents } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { ISearchProduct, SectionFields } from '@boss/types/b2b-b2c';
import { CtaBanner, PromotionCard, Section } from '@boss/ui';

import { ComponentMapper, ProductCardWrapper, RecentlyViewedProducts, SearchGrid } from '../../components';
import { CATEGORY_SEARCH_CONFIG } from '../../constants';
import { useEventTracker, useProductMap, useSearchAttributes } from '../../hooks';
import { categoryPageConfig, expandableFacets, productSortOptions } from '../../utils';

type Props = {
  title: string;
  promotion?: ComponentProps<typeof PromotionCard>;
  attributes: string[];
  categoryType?: string;
  indexName: string;
  suffixSections?: SectionFields[] | null;
  hideTotalResults?: boolean;
};

const CategoryPage = ({
  title,
  promotion,
  attributes: initialAttributes,
  categoryType,
  indexName,
  suffixSections,
  hideTotalResults,
}: Props) => {
  const { mapProductComponent } = useProductMap();
  const { showPaintGuideLink } = categoryPageConfig;
  const { locale, query } = useRouter();
  const [trackedPages, setTrackedPages] = useState<string[]>([]);
  const { t } = useTranslation();
  const { trackCustomEvent } = useEventTracker();

  const { attributes } = useSearchAttributes(CATEGORY_SEARCH_CONFIG.attributesToHide);
  const { results } = useHits<ISearchProduct>();
  const { currentRefinement: currentPage } = usePagination();

  useEffect(() => {
    const resultIdString = results?.hits?.map(product => product.id).join(',');

    const pageWithoutFilters = `${title} - Category page ${currentPage + 1}`;
    const page = `${pageWithoutFilters} - ${resultIdString}`;

    if (results?.hits?.length && !trackedPages.includes(page)) {
      setTrackedPages(prev => [...prev, page]);
      trackCustomEvent(trackEvents.VIEW_LIST, {
        item_list_name: pageWithoutFilters,
        items: results.hits.map(product => ({
          item_id: product.id,
          item_name: product.name,
          item_category: title,
        })),
      });
    }
  }, [currentPage, results, trackedPages, title, trackCustomEvent, categoryType, query]);

  useEffect(() => {
    setTrackedPages([]);
  }, [categoryType]);

  const getActionType = () => {
    if (expandableFacets) {
      return 'expand';
    }

    if (categoryType === 'l1') {
      return 'navigate';
    }

    return 'refine';
  };

  const getAttributes = () => {
    if (categoryType === 'l1' && !expandableFacets) {
      return initialAttributes;
    }

    return initialAttributes?.concat(attributes);
  };

  const ctaBanner = showPaintGuideLink && (
    <CtaBanner
      className="col-span-full"
      cta={{
        label: t('product.ctaBanner.ctaLabel'),
        icon: faArrowRight,
        href: paintguidePageSlugs[locale],
      }}
      description={t('product.ctaBanner.description')}
      prefix="😳"
      title={t('product.ctaBanner.title')}
    />
  );

  const slug = query?.slug ?? [];

  const category1 = slug[0];
  const category2 = slug[1];

  const commonTrackInfo = {
    customEvent: trackEvents.VIEW_LIST_ITEM,
    item_category: category1,
    item_category2: category2,
  };

  return (
    <div>
      <Section
        className="pt-0"
        content={
          <SearchGrid
            actionType={getActionType()}
            attributes={getAttributes()}
            ctaBanner={ctaBanner}
            hideTotalResults={hideTotalResults}
            indexName={indexName}
            promotion={promotion}
            sortOptions={productSortOptions}
            title={title}
          >
            {results?.hits?.map((product, index) => {
              const props = mapProductComponent(product);

              const trackInfo = {
                ...props.trackInfo,
                ...commonTrackInfo,
                item_id: product.id,
                item_name: product.name,
                item_list_name: title,
                index,
              };

              return (
                <ProductCardWrapper key={`category-product-card-${product.id}`} {...props} trackInfo={trackInfo} />
              );
            })}
          </SearchGrid>
        }
      />

      <RecentlyViewedProducts trackInfo={commonTrackInfo} />

      {suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </div>
  );
};

export default CategoryPage;

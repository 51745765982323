import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { participateEvent } from './connector';
import { useProfile } from '../../hooks';

const useParticipateEvent = () => {
  const { t } = useTranslation('common');
  const { data: profile } = useProfile();

  return useMutation({
    mutationFn: async ({ eventId, formData }: { eventId: string; formData: Record<string, unknown> }) =>
      await participateEvent(eventId, {
        ...formData,
        contactpersonid: profile?.extension_ContactPersonId,
      }),
    onError: () => {
      toast.error(t('participate.error', { ns: 'event' }));
    },
  });
};

export { useParticipateEvent };

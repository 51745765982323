import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faPinterest,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBars,
  faBlockBrick,
  faBookOpen,
  faBrush,
  faCartShopping,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleUser,
  faEnvelope,
  faGreaterThan,
  faHeart,
  faHouse,
  faLessThan,
  faLocationDot,
  faMagnifyingGlass,
  faPalette,
  faPhone,
  faRug,
  faSlotMachine,
  faStore,
  faTableList,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';

import leaf from './leaf';
import paintBrush from './paintBrush';
import paintRoller from './paintroller';
import percent from './percent';
import _pointingArrowRight from './pointingArrowRight';

// @TODO: Investigate if that many icons are neccesary. Contentful supports them all
const ICON_MAP = {
  'angle-down': faAngleDown,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'arrow-up': faArrowUp,
  bars: faBars,
  'book-open': faBookOpen,
  brush: faBrush,
  paintBrush: paintBrush,
  'cart-shopping': faCartShopping,
  'chevron-down': faChevronDown,
  'chevron-left': faChevronLeft,
  'chevron-right': faChevronRight,
  'chevron-up': faChevronUp,
  'circle-user': faCircleUser,
  download: faDownload,
  facebook: faFacebook,
  'greater-than': faGreaterThan,
  heart: faHeart,
  house: faHouse,
  instagram: faInstagram,
  leaf,
  'less-than': faLessThan,
  linkedin: faLinkedinIn,
  'location-dot': faLocationDot,
  'magnifying-glass': faMagnifyingGlass,
  'paint-roller': paintRoller,
  roller: paintRoller,
  palette: faPalette,
  percent,
  pinterest: faPinterest,
  rug: faRug,
  'slot-machine': faSlotMachine,
  store: faStore,
  'table-list': faTableList,
  twitter: faTwitter,
  user: faUser,
  wall: faBlockBrick,
  youtube: faYoutube,
  envelope: faEnvelope,
  phone: faPhone,
};

export type PreloadedIcon = keyof typeof ICON_MAP;

export const getPreloadedIcon = (icon: PreloadedIcon) => ICON_MAP[icon];

export const pointingArrowRight = _pointingArrowRight;

import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { ColorGroup, SimpleColor } from '@boss/types/b2b-b2c';
import { ColorPickerModal as ColorPickerModalComponent } from '@boss/ui';

import { useColorByColorTypeGroup, useColorGroups, useColorsByGroup, useSearchColor } from '../../client-queries';
import { COLOR_TYPE } from '../../constants';
import { useFavorites, useRecentViewedColors } from '../../hooks';
import { isB2b } from '../../utils';

type Props = {
  colorGroups?: ColorGroup[] | null;
  colorType?: 'transparant' | 'default';
  initialColorGroup?: ColorGroup;
  initialSearchValue?: string;
  onClose: () => void;
  productId?: string;
  articleId?: string;
  selectedColor?: SimpleColor;
  setSelectedColor: (color: SimpleColor, colorBase?: string) => void;
  setSelectedColorGroup?: (group: ColorGroup) => void;
};

const ColorPickerModal = ({
  colorGroups: initialColorGroups,
  colorType,
  initialColorGroup,
  initialSearchValue = '',
  onClose,
  productId,
  selectedColor,
  setSelectedColor,
  setSelectedColorGroup,
  articleId,
}: Props) => {
  const { t } = useTranslation('product');
  const [searchedValue, setSearchedValue] = useState(initialSearchValue);
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [hasSearched, setHasSearched] = useState(!!initialSearchValue);
  const [activeColorGroup, setActiveColorGroup] = useState<ColorGroup | undefined>(initialColorGroup);

  const {
    data: searchColors,
    isInitialLoading: searchLoading,
    refetch: fetchColors,
  } = useSearchColor({
    value: searchedValue,
    productId,
    enabled: false,
    skuId: articleId,
    colortypegroup: COLOR_TYPE.MYMX,
  });

  const { data: activeColorGroupColors, isLoading: colorsLoading } = useColorsByGroup({
    groupCode: activeColorGroup?.code,
    productId,
    skuId: articleId,
  });

  const { data: premixedColors } = useColorByColorTypeGroup(COLOR_TYPE.PREMIXED, productId ?? '', !isB2b);
  const { viewedColors } = useRecentViewedColors();

  const { data: fetchedColorGroups, isInitialLoading: colorGroupsLoading } = useColorGroups(!initialColorGroups);
  const { favorites, toggleFavorite } = useFavorites();
  const colorGroups = initialColorGroups ?? fetchedColorGroups;

  const getDisclaimer = () => {
    if (colorType === 'transparant') {
      return t('colorPickerModal.disclaimers.transparant');
    }
  };

  const reset = () => {
    setSearchValue('');
    if (setHasSearched) {
      setHasSearched(false);
    }
  };

  const handleChangeColorClick = (color: SimpleColor, colorBase?: string) => {
    setSelectedColor(color, colorBase);
    onClose();
    reset();
  };

  const handleColorGroupClick = (colorGroup: ColorGroup) => {
    setActiveColorGroup(colorGroup);
    setSelectedColorGroup?.(colorGroup);
  };

  useEffect(() => {
    if (!activeColorGroup && colorGroups) {
      setActiveColorGroup(colorGroups[0]);
    }
  }, [activeColorGroup, colorGroups]);

  useEffect(() => {
    if (searchValue.length === 0) {
      setHasSearched(false);
    }
  }, [searchValue, setHasSearched]);

  useEffect(() => {
    fetchColors();
  }, [fetchColors, searchedValue]);

  useEffect(() => {
    if (initialSearchValue) {
      setSearchValue(initialSearchValue);
    }
  }, [initialSearchValue]);

  return (
    <ColorPickerModalComponent
      activeColorGroup={activeColorGroup}
      activeColors={activeColorGroupColors}
      colorGroups={colorGroups}
      colorGroupsLoading={colorGroupsLoading}
      colorType={colorType}
      colorsLoading={colorsLoading}
      defaultColorBase={COLOR_TYPE.MYMX}
      disclaimer={getDisclaimer()}
      favoriteColorIds={favorites.COLORS}
      hasSearched={hasSearched}
      onChangeColorClick={handleChangeColorClick}
      onClose={() => {
        reset();
        onClose();
      }}
      onColorGroupClick={handleColorGroupClick}
      onFavorite={color => toggleFavorite('COLORS', color.code.toString(), color)}
      onSearch={() => {
        setSearchedValue(searchValue);
        setHasSearched(true);
      }}
      premixedColorBase={COLOR_TYPE.PREMIXED}
      premixedColors={
        !isB2b && !!premixedColors?.length
          ? premixedColors?.map(color => ({
              id: color.colorid,
              code: color.colorcode,
              rgb: color.rgb,
              name: color.colorname,
            }))
          : undefined
      }
      recentViewedColors={!isB2b ? viewedColors : undefined}
      searchLoading={!!searchedValue && searchLoading}
      searchResults={searchColors}
      searchValue={searchValue}
      selectedColor={selectedColor}
      setSearchValue={setSearchValue}
      translations={t('colorPickerModal', { ns: 'product', returnObjects: true })}
    />
  );
};

export default ColorPickerModal;
